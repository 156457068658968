<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${logonBackgroundMap})`}">
    <ics-page-header :project-title="projectTitle" :layout="[]" />
    <div v-if="active === 0" class="find-pass-word-inner">
      <div class="">
        <div class="logo-inner" />
      </div>
      <div class="find-pass-word-form">
        <div class="find-pass-word-content">
          <div class="find-pass-word-content-title">
            <i class="el-icon-info" style="color: #1989fa !important;font-size: 24px;" />
            <span style="font-size: 14px;">请输入意愿认证码，以确认签署电子签章合同</span>
          </div>
          <div style="margin-top: 10px;">
            <el-form ref="form" :model="form" :rules="rules" label-width="140px" label-position="right">
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <div style="height: 100px; text-align:center;">
                    <p class="cell-phone">
                      管理员手机号码：{{ accountDetail.mobile }}
                    </p>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <el-form-item
                    prop="smsCode"
                    label="验证码："
                  >
                    <el-input v-model="form.smsCode" style="margin-left:-1px !important;width: 71%;" type="number" placeholder="请输入验证码" />
                    <el-input v-show="false" />
                    <debounce-button v-if="showTime === false" :loading="loading.smsCode" style="padding: 10px 5px !important;vertical-align: middle; width: 92px;" @click="sendVerificationSms">
                      获取验证码
                    </debounce-button>
                    <el-button v-else style="padding: 9px 15px !important;vertical-align: middle;">
                      {{ time }}S后重发
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <el-row :gutter="80">
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <debounce-button :loading="loading.submit" style="width: 10%; height: 40px; font-size: 14px;" type="primary" @click="commitMobile">
            完成
          </debounce-button>
        </el-col>
      </el-row>
    </div>
    <div v-if="active === 1" class="find-pass-word-inner">
      <div class="">
        <div class="logo-inner" />
      </div>
      <div class="find-pass-word-form">
        <div class="find-pass-word-content1">
          <div class="partition-area1" style="margin-top: 10px;">
            <el-form label-width="140px" label-position="right">
              <el-row :gutter="80" type="flex" justify="center">
                <div id="qrCode" ref="qrCodeDiv" class="qrCodeDiv" />
              </el-row>
              <br>
              <el-row v-if="loading.qrCode === false" :gutter="80" type="flex" justify="center">
                <p style="font-size: 14px; color: #FFFFFF">
                  微信扫一扫，进行人脸识别
                </p>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <el-row :gutter="80">
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <debounce-button :loading="loading.submit" :disabled="loading.submit" style="width: 10%; height: 40px; font-size: 14px;" type="primary" @click="commit">
            完成
          </debounce-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import QRCode from "qrcodejs2";
import {cookie} from "../../../assets/js/config";
import IcsPageHeader from '@/components/page-header'
export default {
  components: { IcsPageHeader },
  data () {
    return {
      faceCallback: process.env.VUE_APP_REQUEST_BASE_URL + '/core/b/p/cfcaSign/faceCallback',
      projectTitle: '',
      logonBackgroundMap: '',
      contractId: this.$route.query.contractId,
      changeUrl: '',
      accountDetail: {},
      contractDetail: {},
      time: 300,
      active: 0,
      remember: false,
      showTime: false,
      config: {
        url: '',
        bizToken: ''
      },
      loading: {
        qrCode: false,
        smsCode: false,
        submit: false
      },
      form: {
        smsCode: ''
      },
      rules: {
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  created () {
    this.getDetail()
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    getDetail () {
      this.api.cfcaSign.accountDetail().then(result => {
        const data = result.data.data || {}
        this.accountDetail = data
      }).finally(() => {
      })
    },
    getContractDetail () {
      this.api.contract.sign.getDetail(this.contractId).then(result => {
        this.contractDetail = result.data.data
      }).finally(() => {
      })
    },
    getQrCode () {
      this.active = 1
      this.loading.qrCode = true
      let wPath = window.document.location.href;
      let pathName =  this.$route.path;
      let pos = wPath.indexOf(pathName);
      let localhostPath = wPath.substring(0, pos);
      const data = {
        contractId: this.contractId,
        redirectUrl: `${localhostPath}/api/capital`
      }
      this.api.cfcaSign.signFaceAuth(data).then(res => {
        this.loading.qrCode = false
        this.config.url = res.data.data
        this.$nextTick(function () {
          this.bindQRCode()
        })
      }).finally(() => {
        this.loading.qrCode = false
      })
    },
    sendVerificationSms () {
      if (!this.accountDetail.mobile) {
        this.$message.error('该账号目前未匹配到相关管理员手机号')
        return false
      }
      this.loading.smsCode = true
      this.api.cfcaSign.signSmsSend().then(result => {
        this.$message({
          message: '发送成功',
          type: 'success'
        })
        this.showTime = true
        this.timer = setInterval(() => {
          if (this.time === 0) {
            clearInterval(this.timer)
            this.time = 300
            this.showTime = false
          } else {
            this.time--
          }
        }, 1000)
      }).finally(() => {
        this.loading.smsCode = false
      })
    },
    commitMobile () {
      if (!this.form.smsCode) {
        this.$message.error('请输入验证码')
        return false
      }
      this.loading.submit = true
      this.api.cfcaSign.signSmsValidCod(this.form.smsCode).then(res => {
        this.loading.submit = false
        this.getQrCode()
        this.getContractDetail()
      }).finally(() => {
        this.loading.submit = false
      })
    },
    commit () {
      this.loading.submit = true
      this.api.cfcaSign.signFaceCheck(this.contractId).then(res => {
        if (res.data.data) {
          const data = {
            contractId: this.contractDetail.produceInfoVo.contractId,
            contractName: this.contractDetail.produceInfoVo.contractName,
            fileUrl: this.contractDetail.produceInfoVo.beforeSigninUrlFileId
          }
          this.api.cfcaSign.sign(data).then(result => {
            if (result.data.success === true) {
              this.$message.success('签署完成')
              this.$router.push({ name: 'signDetail', query: { contractId: this.contractId } })
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.$message.error('未完成人脸识别,请先扫描二维码进行人脸识别')
          this.loading.submit = false
          return false
        }
      }).finally(() => {
      })
    },
    bindQRCode: function () {
      var qrcodeCreater = new QRCode(this.$refs.qrCodeDiv, {
        text: this.config.url,
        width: 300,
        height: 300,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
      qrcodeCreater._el.title = ''
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../../../assets/images/demo/login-big.png");
  background-size: 100% 100%;
}
.find-pass-word-inner {
  width: 840px;
  height: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -410px;
  margin-top: -245px;
  padding: 40px 30px 40px;
  background-color: rgba(0, 0, 0, 0.6);
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 30px;
    width: 100%;
    height: 350px;

    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
  .cell-phone {
    color: #015478;
    margin-top: 30px;
    font-size: 18px;
  }
  .find-pass-word-content1 {
    margin-top: 30px;
    width: 100%;
    height: 350px;
  }
  .partition-area1 {
    margin: 24px;
  }
}

</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
