// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/demo/login-big.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-page[data-v-34632927] {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.find-pass-word-inner[data-v-34632927] {\n  width: 840px;\n  height: 550px;\n  border-radius: 5px;\n  box-sizing: border-box;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-left: -410px;\n  margin-top: -245px;\n  padding: 40px 30px 40px;\n  background-color: rgba(0, 0, 0, 0.6);\n}\n.find-pass-word-inner .logo-inner[data-v-34632927] {\n  margin: 0 auto 10px;\n  width: 380px;\n  height: 30px;\n}\n.find-pass-word-inner .logo-inner img[data-v-34632927] {\n  width: 100%;\n  height: 100%;\n}\n.find-pass-word-inner h2[data-v-34632927] {\n  font-size: 25px;\n  line-height: 1;\n  font-weight: 500;\n  color: #ffffff;\n  text-align: center;\n  margin-bottom: 30px;\n}\n.find-pass-word-inner .find-pass-word-content[data-v-34632927] {\n  background: #fff;\n  margin-top: 30px;\n  width: 100%;\n  height: 350px;\n}\n.find-pass-word-inner .find-pass-word-content .find-pass-word-content-title[data-v-34632927] {\n  border-bottom: 1px solid lavender;\n}\n.find-pass-word-inner .cell-phone[data-v-34632927] {\n  color: #015478;\n  margin-top: 30px;\n  font-size: 18px;\n}\n.find-pass-word-inner .find-pass-word-content1[data-v-34632927] {\n  margin-top: 30px;\n  width: 100%;\n  height: 350px;\n}\n.find-pass-word-inner .partition-area1[data-v-34632927] {\n  margin: 24px;\n}\n", ""]);
// Exports
module.exports = exports;
